import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
// import { apiFetchAdmin } from '../helpers/apiFetch';
import { allProfilesCategories } from "./Data/CategoriesData"
import { addWorkform } from "../helpers/apiAxios"

export function AddWorkProfile() {
    const [errorMessage, setErrorMessage] = useState('');
    const [error, setError] = useState(null);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm(
        // {
        //     defaultValues: {
        //         description: undefined
        //     }
        // }
    );
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    // const [settingAllBooks, setSettingAllBooks] = useState("");
    // const [selectedCategory, setSelectedCategory] = useState(null);


    const thisYear = new Date().getFullYear();




    async function onSubmit(formData) {
        setLoading(true);
        console.log(formData);


        try {
            const response = await addWorkform(formData);

            if (response.data.isSuccess) {
                setDataUpdate(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                console.log(formData);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى", response.data.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
        }
    }


    return (
        <div id="add-work"
            className="modal fade bg-transparent"
            tabIndex="-1"
            aria-hidden="true"
            aria-labelledby="moderatorAddForm"
            data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="moderatorAddForm">إضافة نموذج عمل</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-0">
                        <form
                            encType="multipart/form-data" method="post"
                            className='d-flex gap-2 text-start pe-5 ps-3 pb-4'
                            onSubmit={handleSubmit(onSubmit)}>

                            <div className="d-flex flex-column col-11 mx-auto my-5 gap-3">
                                <div className="input-group input-group-sm">
                                    <span className="input-group-text">التصنيف</span>
                                    <select className="form-select" aria-label="add-works-category"
                                        name='service'
                                        {...register("service", { required: true })}
                                    >
                                        <option value="">اختر التصنيف</option>
                                        {allProfilesCategories.map((category, index) =>
                                            <option key={index} value={category.en}>{category.ar}</option>
                                        )}
                                    </select>
                                    {errors.category && <p className='errors-msg'>مطلوب</p>}
                                </div>


                                <label htmlFor='upload-work' className="input-group-text" >أضف الصورة</label>
                                <input id='upload-work' className='form-control'
                                    type="file"
                                    accept="image/JPG"
                                    onChange={e => setValue("thumbnail", e.target.files[0])}
                                />
                                {errors.thumbnail && <p className='errors-msg'>يجب رفع صورة</p>}

                            </div>
                            <button
                                className='btn-masar btn-dark-orange text-white col-2 me-3 mb-3 position-absolute bottom-0 end-0'
                                type="submit">
                                {loading &&
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                }
                                إضافة
                            </button>

                            {/* <input className='btn btn-dark-orange text-white col-2 me-3 mb-3 position-absolute bottom-0 end-0 ' type="submit" value="إضافة" /> */}

                        </form>
                        {errorMessage &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {errorMessage}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}

                        {dataUpdate &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                تم إضافة الصورة
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}

                    </div>
                </div>
            </div>
        </div>

    )
}

