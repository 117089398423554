import { handleFileChange } from "../helpers/HandleFunctions/HandleFileUpload"

export default function ServiceTermsAndUpload({ errors, setValue, register, setError, fileError }) {

    return (
        <div className="d-flex flex-wrap flex-md-nowrap col-12">
            <div className="col-12 col-md-5">
                <div className="form-check">
                    <input className="form-check-input"
                        defaultChecked={true}
                        defaultValue={true}
                        type="checkbox" id="checkWhatsApp"
                        onChange={e => setValue("contactWithWhatsApp", e.target.checked)}
                        {...register("contactWithWhatsApp")}
                    />
                    <label className="form-check-label" htmlFor="checkWhatsApp">التواصل عبر الواتساب</label>
                </div>
                <div className="form-check">
                    <input className="form-check-input"
                        defaultChecked={true}
                        defaultValue={true}
                        type="checkbox" id="checkEmail"
                        {...register("contactWithEmail")}
                        onChange={e => setValue("contactWithEmail", e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="checkEmail">التواصل عبر البريد الإلكتروني</label>
                </div>

            </div>

            <div className="col">
                <label htmlFor="file">ارفاق ملف</label>
                <span className="notice-font px-2 opacity-75">اقصى حجم للملف 15MB</span>
                {fileError ? <span className="notice-font bg-danger text-center p-1 rounded">{fileError}</span> : ""}
                <input
                    className="form-control form-control-sm mb-4" type="file" id="file"
                    accept=".doc,.docx,.pdf,image/*"
                    onChange={(e) => handleFileChange(e, setValue, setError)}
                />
                {errors.file && <p className="text-danger">{errors.file.message}</p>}
            </div>

        </div>

    )
}