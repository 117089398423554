import { Outlet } from "react-router-dom";
import { AdminHeader } from "../components/common/Header/Header";
import { FooterSmall } from "../components/common/Footer/Footer";
import MsgGetToken from '../helpers/MsgGetToken';
import 'react-toastify/dist/ReactToastify.css';


export default function AdminLayout() {

    return (
        <>
            <AdminHeader />
            <main style={{ minHeight: "calc(100vh - 145px)" }}>
                <Outlet />
                <MsgGetToken />
            </main>
            <FooterSmall />
        </>
    );
}

