export default function ServiceSubServiceChoice({ subServNAME, bookService, getValues, setValue }) {
    return (
        <>
            <h6>الخدمة المطلوبة</h6>
            <div className="d-flex flex-wrap mb-3 bg-white-70 text-black p-3 rounded">

                {
                    subServNAME.map((subServ, index) => (
                        <div key={index} className="col-6 col-sm-4 col-md-3 w-sm-25 d-flex flex-wrap my-2 flex-fill justify-content-between ">
                            {bookService &&
                                <div className="w-100">
                                    <img className="subServ-icon mt-2" src={subServ.icon} alt={`subServ_${subServ.idName}`} />
                                </div>
                            }
                            <div>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={subServ.idName}
                                    value={subServ.value}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const checked = e.target.checked;
                                        const currentValues = getValues("subServices").split(',').filter(Boolean); // split and remove empty strings
                                        let updatedValues;

                                        if (checked) {
                                            updatedValues = [...currentValues, value];
                                        } else {
                                            updatedValues = currentValues.filter(v => v !== value);
                                        }

                                        setValue("subServices", updatedValues.join(','), { shouldValidate: true });
                                    }}
                                />
                                <label htmlFor={subServ.idName} className="mt-1 ms-1 form-check-label">
                                    {subServ.arName}
                                </label>

                            </div>

                        </div>
                    ))
                }
            </div>
        </>
    )
}