import UserSideBar from "../components/UserSideBar";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserReqDetials } from "../components/UserReqDetials";
import { apiFetch } from '../helpers/apiFetch';
import moment from 'moment';
import useCountdownTimer from "../helpers/useCountdownTimer"
import Timer from "../helpers/timer";
import Pagination from "../helpers/Pagination"
import { SearchInput } from "../components/common/Search";
import { allServicesCategories, allStatusListItems, allSubServices } from "../components/Data/CategoriesData";
import { handlePDFClick } from "../helpers/HandleFunctions/HandlePDFOpen"
// import { userOfferUpdate } from "../helpers/apiAxios"

export default function MyRequests({ orderTimerCreated }) {
    const { handleSubmit, setValue, formState: { errors } } = useForm();
    const [requestData, setRequestData] = useState(null);
    // const [allRequestsData, setAllRequestsData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    // const [userApproval, setUserApproval] = useState(false);
    const [profile, setProfile] = useState(null);
    moment.locale('en');
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');



    // const userImage = requestData?.data?.image
    // const [image, setImage] = useState("");



    // const page = requestData?.data?.paginationInfo.pageNumber;
    // const pagesCount = requestData?.data?.paginationInfo.totalCount;
    const totalPages = requestData?.data?.paginationInfo.totalPagesCount;
    const userName = profile?.data?.firstName;
    // console.log(pagesCount);

    const dataRequests = requestData?.data?.result;
    // console.log(dataRequests);


    // const arabicStatus = allStatusListItems.map(state => state.en === allRequestsData?.data?.result?.status ? state.ar : "")
    // console.log(arabicStatus);

    //Search Query filtering 
    const filteredReqestsData = requestData?.data?.result.filter(data =>
        data.service.toLowerCase().includes(searchQuery.toLowerCase()) ||
        data.status.toLowerCase().includes(searchQuery) ||
        data.id.toString().includes(searchQuery) ||
        data.description.toLowerCase().includes(searchQuery)
    );


    useEffect(() => {
        async function fetchRequests() {
            try {
                const profileData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
                const data = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/orders/my-orders${searchQuery ? "" : (`?page=${currentPage}&limit=5`)}`);
                // const allData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/orders/my-orders`);
                setProfile(profileData);
                setRequestData(data);
                // setAllRequestsData(allData);

            } catch (err) {
                setError("هناك مشكلة تواصل مع الدعم");
            }
        }

        fetchRequests();
    }, [currentPage, searchQuery]);
    // const selectedBookCategory = allServicesCategories.find(cat => cat.en === selectedBook?.data?.category.type)
    // const shownService = allServicesCategories.find(cat => cat.en === requestData?.data?.result[0].service)

    // const { time, reset, stop, start, isRunning } = useCountdownTimer(2 * 60 * 60);

    // const formatTime = (seconds) => {
    //     const hrs = Math.floor(seconds / 3600);
    //     const mins = Math.floor((seconds % 3600) / 60);
    //     const secs = seconds % 60;
    //     return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    // };

    // default reply hours
    const addHours = 24;

    // const currentDate = new Date(orderTimerCreated).getTime();
    // const createTime = new Date(orderTimerCreated).getTime();
    // const updatedTime = new Date(createTime + addHours * 60 * 60 * 1000).getTime();


    function Requests({ index, orderId, serviceName, serviceId, numberOfForms, language, orderDescription, orderCreated, orderUpdated, orderUser, subServices, orderStatus, orderStatusEn, isUserApproved, offerStatus, offerFile, offerPrice, offerNote, customerFile }) {

        async function onSubmit(data) {
            setLoading(true);
            console.log("Form data: ", data, orderId); // Debug: Check form data
            // setValue("status", e.target.value)

            try {
                const response = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/orders/${orderId}`, {
                    method: "PATCH",
                    body: JSON.stringify(data)
                });

                // Redirect to another page after successful submission
                if (response.isSuccess) {
                    // navigate('/');
                    setTimeout(() => {
                        window.location.reload();
                        // navigate('/masarLibrary');
                    }, 1000);
                    setDataUpdate(true)
                } else {
                    console.log(response, response.message, response.status);
                    setErrorMessage("حدث خطأ ما، حاول مرة أخرى", response.message);
                }
            } catch (error) {
                console.error("Error during form submission: ", error); // Debug: Check error
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
            } finally {
                setLoading(false);

                // navigate('/masarLibrary');

            }
        }


        return (
            <tr
            // className={`${orderStatus === "Pending"
            //     && "table-warning"} ${orderStatus === "Completed"
            //     && "table-success"}`}
            >

                <td className="col " > {`${moment(orderCreated).format('YY')}${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 3 }).format(orderId,)}`}</td>
                <td className="col-2">
                    {moment(orderCreated).format('DD-MM-YYYY')}</td>
                <td className="col-5">{serviceName}<br />
                    <span
                        className="text-decoration-underline c-lightBlue"
                        data-bs-toggle="modal"
                        data-bs-target={`#user-order-details-${new Intl.NumberFormat("en-IN", { minimumIntegerDigits: 3 }).format(orderId,)}`}>
                        التفاصيل</span></td>
                <td className="col-2 text-center">{orderStatusEn === "Paid" || "Approved" || "Rejected" ? orderStatus : ""}
                    <button className={`btn-masar-sm ${offerFile && "btn btn-success"}`}
                        value={offerFile}
                        onClick={offerFile && handlePDFClick}>
                        {offerFile ? "عرض السعر" : "جاري العمل"}</button></td>
                <td className="col-2 text-center">
                    {orderStatusEn === "Paid" ? <p className="text-success m-0">تم الدفع</p>
                        : <>{orderStatusEn === "Approved" ?
                            <p className="c-darkOrange m-0">في انتظار الدفع</p>
                            : <>{orderStatusEn === "Rejected" ?
                                <p className="c-darkOrange m-0">جاري المراجعة</p>
                                : <>
                                    {offerFile ?
                                        <form className='d-flex flex-column gap-1 text-center align-contents-center'
                                            onSubmit={handleSubmit(onSubmit)}>

                                            <button className="btn-masar-sm btn-dark-orange "
                                                value="Approved"
                                                type="submit"
                                                onClick={(e) => {
                                                    setValue("status", e.target.value);
                                                }}
                                            >اضف للسلة</button>
                                            <button className="btn-masar-sm btn-med-gray"
                                                value="Rejected"
                                                type="submit"
                                                onClick={(e) => {
                                                    setValue("status", e.target.value);
                                                }}
                                            >رفض</button>
                                        </form> :
                                        <Timer
                                            OrderCreateAt={orderCreated}
                                            H={addHours} />
                                    }
                                </>
                            } </>}
                        </>
                    }
                    <UserReqDetials
                        orderId={orderId}
                        numberOfForms={numberOfForms}
                        language={language}
                        orderDescription={orderDescription}
                        subServices={subServices}
                        orderStatus={orderStatus}
                        serviceName={serviceName}
                        offerFile={offerFile}
                        offerNote={offerNote}
                        offerPrice={offerPrice}
                        customerFile={customerFile}
                    />
                </td>

            </tr>
        )

    };


    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!requestData) {
        return <div className="loader"></div>;
    }

    // console.log(allSubServices);


    return (
        <div className='container dashboard col-12 position-relative d-flex'>
            <div className='container col-1 col-sm-2 m-0 text-start'>
                <UserSideBar />
            </div>
            <div className='container mt-4 col-11 col-md-10'>
                <h6 className="text-md-end m-0 c-darkOrange text-center"><span className="text-black">أهلا/</span>
                    {userName}
                </h6>
                <div className="container mt-3 rounded h-75 bg-dark-blue">
                    <div className="d-flex flex-wrap justify-content-center ">
                        {dataRequests.length > 0 &&
                            <SearchInput
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                            />}
                        <div className="container mt-3 rounded bg-light-gray position-relative p-3 overflow-auto">
                            {dataRequests.length > 0 ?
                                <table className="table table-hover">
                                    <thead className="table-dark">
                                        <tr className="align-middle">
                                            <th scope="col" className="">كود الطلب</th>
                                            <th scope="col">تاريخ الطلب</th>
                                            <th scope="col">تفاصيل الخدمة</th>
                                            <th scope="col" className="">حالة الطلب</th>
                                            <th scope="col" className="text-center">الوقت المتبقي</th>
                                        </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                        {searchQuery ?
                                            <>
                                                {filteredReqestsData.map((request, index) => (
                                                    <Requests
                                                        key={index}
                                                        orderId={request.id}
                                                        serviceName={
                                                            allServicesCategories.map(cat => cat.en === request.service ? cat.ar : "")}
                                                        orderCreated={request.createdAt}
                                                        orderTimerCreated={request.createdAt}
                                                        orderUpdated={request.updatedAt}

                                                        orderStatusEn={request.status}
                                                        orderStatus={allStatusListItems.map(state => state.en === request.status ? state.ar : "")}
                                                        numberOfForms={request.numberOfForms}
                                                        language={request.language}
                                                        orderDescription={request.description}

                                                        subServices={request.subServices &&
                                                            request.subServices.map((e, index) => {
                                                                const matchedSubService = allSubServices.find(subServ => subServ.en === e);
                                                                return matchedSubService ? (index > 0 ? " - " : "") + matchedSubService.ar : "";
                                                            }).filter(Boolean) // This removes any empty strings
                                                        }

                                                        offerFile={request.offerDetails?.file}
                                                        offerPrice={request.offerDetails?.price}
                                                        offerNote={request.offerDetails?.notes}
                                                        customerFile={request.file}
                                                    />

                                                ))}
                                            </>
                                            :
                                            <>
                                                {dataRequests.map((request, index) => (
                                                    <Requests
                                                        key={index}
                                                        orderId={request.id}
                                                        serviceName={
                                                            allServicesCategories.map(cat => cat.en === request.service ? cat.ar : "")}
                                                        orderCreated={request.createdAt}
                                                        orderTimerCreated={request.createdAt}
                                                        orderUpdated={request.updatedAt}

                                                        orderStatusEn={request.status}
                                                        orderStatus={allStatusListItems.map(state => state.en === request.status ? state.ar : "")}
                                                        numberOfForms={request.numberOfForms}
                                                        language={request.language}
                                                        orderDescription={request.description}

                                                        subServices={request.subServices &&
                                                            request.subServices.map((e, index) => {
                                                                const matchedSubService = allSubServices.find(subServ => subServ.en === e);
                                                                return matchedSubService ? (index > 0 ? " - " : "") + matchedSubService.ar : "";
                                                            }).filter(Boolean) // This removes any empty strings
                                                        }

                                                        offerFile={request.offerDetails?.file}
                                                        offerPrice={request.offerDetails?.price}
                                                        offerNote={request.offerDetails?.notes}
                                                        customerFile={request.file}

                                                    />

                                                ))}
                                            </>
                                        }
                                    </tbody>
                                </table>
                                :
                                <p className="text-center mt-2">لا يوجد طلبات حتى الآن</p>
                            }
                        </div>
                        {totalPages > 1 &&
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages} />
                        }

                    </div>
                </div>
            </div>
        </div>
    )
};

// to Add 
// Ordar Approved or Rejected from user




// {
//     Array.from({ length: 4 }, (_, index) => (
//         <li key={index + 1} className="page-item">
//             {/* <a className="page-link" href="#">1</a> */}
//             <Link
//                 className="page-link text-black"

//                 onClick={() => goToPage(index + 1)}
//                 disabled={currentPage === index + 1}
//             >
//                 {index + 1}
//             </Link>
//         </li>
//     ))
// }

