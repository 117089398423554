import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Layout from "./pages/layout";
import UserLayout from "./pages/userLayout";
import ServicesLayout from "./pages/servicesLayout";

import Home from "./pages/home";
import Services from "./pages/services";
import AboutUs from "./pages/aboutUs";
import Profile from "./pages/profile";
import Library from "./pages/masarLibrary";

// Services Pages
import ServBooks from "./pages/servBooks";
import ServWeb from "./pages/servWeb";
import ServSocial from "./pages/servSocial";
import ServIdentity from "./pages/servIdentity";
import ServAdv from "./pages/servAdv";
import ServPackage from "./pages/servPackage";
import ServBanner from "./pages/servBanner";
import ServPhoto from "./pages/servPhoto";
import ServShows from "./pages/servShows";
import ServProfile from "./pages/servProfile";


import LogIn from "./pages/logIn";
import Register from "./pages/register";
import OTPPage from "./pages/OTP";
import MyAccount from "./pages/userAccount";
import AdminLayout from "./pages/AdminLayout";
import AdminLogIn from "./pages/adminLogIn";
import AdminAccount from "./pages/adminDash";
import AdminNotifi from "./pages/adminNotifi";
import AdminModirators from "./pages/adminModirators";
import AdminSetting from "./pages/adminSetting";
import { SettingBooks } from "./pages/settingBooks";
import { SettingServices } from "./pages/settingServices";
import { SettingPayments } from "./pages/settingPayments";
import { SettingUsers } from "./pages/settingUsers";
import { SettingProfile } from "./pages/settingProfile";
import MyRequests from "./pages/userRequests";
import MyPayments from "./pages/userPayments";
import { Terms, Policy } from "./pages/terms";
import MyCart from "./pages/userCart";
import { ServicesProviders } from "./pages/servicesProviders";
import ContactUs from "./pages/contactUs";
import NotFound from "./pages/notFound";
import { PaymentForm } from "./components/CheckoutPaymob";
import ActiveUserPassword from "./pages/registerActiveUser";
import ResetPassword from "./pages/resetPassword";
import LibraryBook from "./pages/libraryBook";

// lazyload pages
// const ServBooks = lazy(() => import("./pages/servBooks"));
// const ServWeb = lazy(() => import("./pages/servWeb"));
// const ServSocial = lazy(() => import("./pages/servSocial"));
// const ServIdentity = lazy(() => import("./pages/servIdentity"));
// const ServAdv = lazy(() => import("./pages/servAdv"));
// const ServPackage = lazy(() => import("./pages/servPackage"));
// const ServBanner = lazy(() => import("./pages/servBanner"));
// const ServPhoto = lazy(() => import("./pages/servPhoto"));
// const ServShows = lazy(() => import("./pages/servShows"));
// const ServProfile = lazy(() => import("./pages/servProfile"));
// const AdminAccount = lazy(() => import("./pages/adminDash"));
// const AdminLogIn = lazy(() => import("./pages/adminLogIn"));




function App() {
  return (
    <BrowserRouter>
      {/* // basename={process.env.PUBLIC_URL} */}
      <ScrollToTop />
      <Suspense fallback={<div className="loader"></div>}>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="services" element={<ServicesLayout />}>
              <Route index element={<Services />} />
              <Route path="servBooks" element={<ServBooks />} />
              <Route path="servWeb" element={<ServWeb />} />
              <Route path="servSocial" element={<ServSocial />} />
              <Route path="servIdentity" element={<ServIdentity />} />
              <Route path="servPackage" element={<ServPackage />} />
              <Route path="servAdv" element={<ServAdv />} />
              <Route path="servBanner" element={<ServBanner />} />
              <Route path="servPhoto" element={<ServPhoto />} />
              <Route path="servShows" element={<ServShows />} />
              <Route path="servProfile" element={<ServProfile />} />
            </Route>
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="profile" element={<Profile />} />
            <Route path="masarLibrary" element={<Library />} />
            <Route path="masarLibrary/:bookSetId" element={<LibraryBook />} />

            <Route path="terms" element={<Terms />} />
            <Route path="policy" element={<Policy />} />
            <Route path="servicesProviders" element={<ServicesProviders />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="payment" element={<PaymentForm />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route element={<UserLayout />}>
            <Route path="/auth/reset-password/:token" element={<ActiveUserPassword />} />
            <Route path="logIn" element={<LogIn />} />
            <Route path="register" element={<Register />} />
            <Route path="OTP" element={<OTPPage />} />
            <Route path="resetPassword" element={<ResetPassword />} />
            <Route path="myAccount" element={<MyAccount />} />
            <Route path="myRequests" element={<MyRequests />} />
            <Route path="myBasket" element={<MyCart />} />
            {/* <Route path="myFav" element={<MyFavorits />} /> */}
            <Route path="myPayments" element={<MyPayments />} />
          </Route>

          <Route element={<AdminLayout />}>
            <Route path="adminLogin" element={<AdminLogIn />} />
            <Route path="admin" element={<AdminAccount />} />
            <Route path="adminNotifi" element={<AdminNotifi />} />
            <Route path="modirators" element={<AdminModirators />} />
            <Route path="setting" element={<AdminSetting />} >
              <Route path="books" element={<SettingBooks />} />
              <Route index element={<SettingBooks />} />
              <Route path="services" element={<SettingServices />} />
              <Route path="payments" element={<SettingPayments />} />
              <Route path="profile" element={<SettingProfile />} />
              <Route path="users" element={<SettingUsers />} />
            </Route>

          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
