
export const handleFileChange = (e, setValue, setError) => {
    const file = e.target.files[0];
    if (file) {
        if (file.size > 15000 * 1024) {
            setError('حجم الملف يجب أن يكون أقل من 15 ميجابايت');
        }
        // setFileString(file);
        setValue('file', file);
    }
};