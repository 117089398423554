import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// import { apiFetchAdmin } from '../helpers/apiFetch';
import { apiFetchAdmin } from '../helpers/apiFetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { addModiratorsForm } from "../helpers/apiAxios"
import Cookies from 'js-cookie';
import { emailValidation, mobileValidation } from "../helpers/Validations/inputsValidations"
import { handleImageChange } from "../helpers/HandleFunctions/HandelImageChange"


export function AddModirators() {
    const [errorMessage, setErrorMessage] = useState('');
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const roles = ["superAdmin", "admin", "moderator"];

    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setImage(reader.result);
    //     };
    //     setValue("image", file);
    //     if (file) {
    //         reader.readAsDataURL(file);
    //     }
    // };


    async function onSubmit(formData) {
        setLoading(true);

        try {
            const response = await addModiratorsForm(formData);

            if (response.data.isSuccess) {
                setDataUpdate(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
        }
    }

    return (
        <div id="add-moderator"
            className="modal fade bg-transparent"
            tabIndex="-1"
            aria-hidden="true"
            aria-labelledby="moderatorAddForm"
            data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="moderatorAddForm">إضافة مشرف</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-0">
                        <form encType="multipart/form-data" method="post" className='d-flex gap-2 text-start pe-5 ps-3 pb-4 '
                            onSubmit={handleSubmit(onSubmit)}>

                            <div className="profile-image-container position-relative my-auto ms-2">
                                <img className="profile-image mx-auto"
                                    crossOrigin="anonymous"
                                    src={image ? image : '../images/user0-img.jpg'} alt="uploading" />
                                <h6 className="profile-change-text border text-center">تغيير الصورة</h6>
                                <input
                                    className="opacity-0 profile-image-input"
                                    type="file"
                                    name='image'
                                    accept="image/jpeg"
                                    onChange={(e) => handleImageChange(e, setValue, setImage,
                                        setErrorMessage)}
                                />
                            </div>

                            <div className="d-flex flex-column col-8 mx-auto my-5 gap-3">
                                <div className="input-group input-group-sm">
                                    <span className="input-group-text">الاسم الأول</span>
                                    <input className={`form-control ${errors.firstName && "border border-danger"}`}
                                        type="text"
                                        name="firstName"
                                        placeholder="الاسم الأول"
                                        {...register("firstName", { required: true, minLength: 3 })}
                                    />
                                    {errors.firstName && <p className='errors-msg'>الاسم مطلوب أكبر من ٣ أحرف</p>}
                                </div>
                                <div className="input-group input-group-sm">
                                    <span className="input-group-text">الاسم الأخير</span>
                                    <input className={`form-control ${errors.lastName && "border border-danger"}`}
                                        type="text"
                                        name="lastName"
                                        placeholder="الاسم الأخير"
                                        {...register("lastName", { required: true, minLength: 3 })}
                                    />
                                    {errors.lastName && <p className='errors-msg'>الاسم مطلوب أكبر من ٣ أحرف</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >البريد</span>
                                    <input
                                        className='form-control'
                                        type="text" placeholder="البريد الالكتروني"
                                        name="email"
                                        // onChange={e => setValue("email", e.target.value)}

                                        // onChange={e => setEmail(e.target.value)}
                                        {...register("email", { required: true, pattern: emailValidation })}
                                    />
                                    {errors.email && <p className='errors-msg'>أدخل البريدالالكتروني</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >رقم الجوال</span>
                                    <input className='form-control text-start' type="tel" placeholder="05xxxxxxxx"
                                        name='mobilePhone'
                                        // onChange={e => setValue("password", e.target.value)}

                                        // onChange={e => setMobilePhone(e.target.value)}
                                        {...register("mobilePhone", { required: true, pattern: mobileValidation })}
                                    />
                                    {errors.mobilePhone && <p className='errors-msg'>أدخل رقم الجوال الصحيح</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >كلمةالسر</span>
                                    <input className='form-control'
                                        type={showPassword ? "text" : "password"} placeholder="كلمةالسر"
                                        name='password'
                                        // onChange={e => setValue("password", e.target.value)}

                                        // onChange={e => setPassword(e.target.value)}
                                        {...register("password", { required: true, minLength: 8, maxLength: 16 })}
                                    />
                                    <span className="input-group-text"
                                        onClick={() => setShowPassword(!showPassword)}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </span>
                                    {errors.password && <p className='errors-msg'>كلمة المرور تكون من ٨-١٠ أحرف</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >المسؤولية</span>
                                    <select className='form-select text-start'
                                        name='role'
                                        // onChange={e => setRole(e.target.value)}
                                        // onChange={e => setValue("role", e.target.value)}

                                        {...register("role", { required: true })}
                                    >
                                        {roles.map((oneRole, index) => (
                                            <option key={index} value={oneRole}>
                                                {oneRole}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* <div className="input-group input-group-sm">
                                    <span className="input-group-text">الصورة</span>
                                    <input
                                        className='form-control'
                                        type="file"
                                        accept="image/*"
                                        id='imageUploader'
                                        onChange={e => setValue("image", e.target.files[0])}
                                        // {...register("image")}
                                        name='image'
                                    />
                                    {errors.image && <p className='errors-msg'>يجب أن يكون صورة</p>}
                                </div> */}
                            </div>
                            <button
                                className='btn-masar btn-dark-orange text-white col-2 me-3 mb-3 position-absolute bottom-0 end-0'
                                type="submit">
                                {loading &&
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                }
                                إضافة
                            </button>
                        </form>
                        {errorMessage &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {errorMessage}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}
                        {dataUpdate &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                تم إضافة مشرف بنجاح .. شكرا لك
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}
