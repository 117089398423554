import ServSideBar from "../components/ServSideBar";
import UnderPrepare from "../components/underPrepare"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { subServSocial, subServSocialGeneral } from "../components/SubServices"
import { SevrHeadName } from "../components/SevrHeadName";

// import UnderPrepare from "../components/underPrepare"
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { Link } from "react-router-dom";

import { userServiceReqest } from "../helpers/apiAxios"
import ServiceTermsAndUpload from "../components/ServiceTermsAndUpload";
import ServiceSubmitButton from "../components/orders/ServiceSubmitButton";
import SuccessErrorMsg from "../components/SuccessErrorMsg";
import ServiceLocationDescInput, { ServiceNumberOfForms, ServiceSelectLang } from "../components/orders/ServiceGeneralInputs";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';


export default function ServSocial() {
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            subServices: '',
        }
    });
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successNotice, setSuccessNotice] =
        useState("تم إرسال طلبكم بنجاح..شكرا لك .. جاري الانتقال للصفحة الرئيسية");
    const navigate = useNavigate();
    const [fileError, setFileError] = useState('');


    async function onSubmit(formData) {
        if (!Cookies.get('token')) {
            return (
                toast.warn(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link> لطلب الخدمة</p>)
            )
        }

        setLoading(true);

        console.log(formData);

        try {
            const response = await userServiceReqest(formData);

            if (response.data.isSuccess) {
                toast.success(successNotice)
                setTimeout(() => {
                    navigate("/");
                }, 3000);

            } else {

                setErrorMessage(toast.error(`خطأ: حدث خطأ ما، تواصل مع الدعم`));
                // setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
            }
        } catch (error) {

            console.error("Error during form submission: ", error);
            switch (error.response.status) {
                case 413:
                    setErrorMessage(toast.warn("حجم الملف كبير"));
                    break;
                case 403:
                    setErrorMessage(toast.error(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link></p>));
                    break;
                case 500:
                    setErrorMessage(toast.error(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link> أو تواصل مع الدعم</p>));
                    break;
                case 400:
                    setErrorMessage(toast.warn("تأكد من إدخال جميع البيانات بالشكل الصحيح"));
                    break;
                default:
                    setErrorMessage(toast.warn("حدث خطأ ما، تواصل مع الدعم"));
            }
        } finally {
            setLoading(false);
        }
    }


    return (
        <>
            <div className="container d-md-flex">
                <div>
                    <ServSideBar />
                </div>
                <div>
                    <SevrHeadName title="سوشيال ميديا" />
                    <div className="container rounded bg-dark-blue p-4 w-100 mx-auto text-white">
                        <h5>تفاصيل الطلب</h5>
                        <h6>كل الباقات تحتوي على</h6>
                        <div className="d-flex flex-wrap mb-3 bg-white-70 text-black p-3 rounded">

                            <ul className="my-2">
                                {subServSocialGeneral.map((items, index) => (
                                    <li key={index}>{items}</li>
                                ))}
                            </ul>
                        </div>
                        <form encType="multipart/form-data" method="post" className="mt-3" onSubmit={handleSubmit(onSubmit)}>

                            <input className="d-none" type="text"
                                {...register("service")} value="Digital Marketing" />

                            <ServiceNumberOfForms
                                register={register} noNumbers={true}
                            />

                            <h6>اختر احد الباقات</h6>
                            <div className="d-flex flex-wrap gap-3 mb-4 justify-content-around">
                                {subServSocial.map((subServ, index) => (
                                    <div key={index} className="card col-12 col-md-5 border-0">
                                        <div className={`text-center text-white card-header bg-dark-orange`}>
                                            {subServ.arTitle}
                                        </div>
                                        <div className={`text-black card-body bg-light-gray`}>
                                            <ul className="p-0">
                                                {subServ.packDetails
                                                    .map((point, index) =>
                                                        <li key={index} className="social-list list-group-item text-wrap ms-3 my-2 postition-relative">
                                                            <FontAwesomeIcon className="pe-4 icon position-absolute pt-3 top-0 translate-middle c-darkBlue" icon={faCheck} />
                                                            {point}
                                                        </li>
                                                    )}
                                            </ul>

                                        </div>
                                        <div className={`p-0 text-center text-white card-footer bg-transparent`}>
                                            <input
                                                className="form-check-input btn-check"
                                                name="sub-Servie-Social "
                                                autoComplete="off"
                                                type="radio" id={subServ.idName}
                                                value={subServ.value}
                                                onChange={e => setValue("subServices", e.target.value)}
                                                {...register("subServices", { required: true })} />
                                            <label htmlFor={subServ.idName} className="btn btn-outline-secondary rounded-0 m-0 form-check-label w-100">{subServ.price} <span>ريال سعودي / الشهر</span></label>
                                        </div>
                                    </div>
                                ))}
                            </div>


                            <ServiceSelectLang
                                register={register}
                                noLang={true}
                            />

                            <ServiceLocationDescInput
                                register={register}
                                noLocation={true}
                                errors={errors}
                            />


                            <ServiceTermsAndUpload
                                fileError={fileError}
                                setError={setFileError}
                                errors={errors}
                                setValue={setValue}
                                register={register} />


                            <ServiceSubmitButton

                                loading={loading} />

                        </form>
                        <SuccessErrorMsg
                            ToastContainer={ToastContainer}
                            errorMessage={errorMessage}
                            dataUpdate={dataUpdate}
                            successMessage={"تم إرسال طلبكم بنجاح..شكرا لك .. جاري الانتقال للصفحة الرئيسية"} />

                    </div>
                </div>
            </div>
        </>
    );
};

