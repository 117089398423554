import ServSideBar from "../components/ServSideBar";
import UnderPrepare from "../components/underPrepare"
import { SevrHeadName } from "../components/SevrHeadName";
// import UnderPrepare from "../components/underPrepare"
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { userServiceReqest } from "../helpers/apiAxios"
import ServiceTermsAndUpload from "../components/ServiceTermsAndUpload";
import ServiceSubmitButton from "../components/orders/ServiceSubmitButton";
import SuccessErrorMsg from "../components/SuccessErrorMsg";
import ServiceLocationDescInput, { ServiceNumberOfForms, ServiceSelectLang } from "../components/orders/ServiceGeneralInputs";
import { useNavigate } from 'react-router-dom';
import ServiceSubServiceChoice from "../components/ServiceSubServiceChoice";
import { subServPackage } from "../components/SubServices";
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';


export default function ServPackage() {
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            subServices: '',
        }
    });
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successNotice, setSuccessNotice] =
        useState("تم إرسال طلبكم بنجاح..شكرا لك .. جاري الانتقال للصفحة الرئيسية");
    const navigate = useNavigate();
    const [fileError, setFileError] = useState('');


    async function onSubmit(formData) {
        if (!Cookies.get('token')) {
            return (
                toast.warn(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link> لطلب الخدمة</p>)
            )
        }
        setLoading(true);

        console.log(formData);

        try {
            const response = await userServiceReqest(formData);

            if (response.data.isSuccess) {
                toast.success(successNotice)
                setTimeout(() => {
                    navigate("/");
                }, 3000);

            } else {

                setErrorMessage(toast.error(`خطأ: حدث خطأ ما، تواصل مع الدعم`));
                // setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
            }
        } catch (error) {

            console.error("Error during form submission: ", error);
            switch (error.response.status) {
                case 413:
                    setErrorMessage(toast.warn("حجم الملف كبير"));
                    break;
                case 403:
                    setErrorMessage(toast.error(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link></p>));
                    break;
                case 500:
                    setErrorMessage(toast.error(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link> أو تواصل مع الدعم</p>));
                    break;
                case 400:
                    setErrorMessage(toast.warn("تأكد من إدخال جميع البيانات بالشكل الصحيح"));
                    break;
                default:
                    setErrorMessage(toast.warn("حدث خطأ ما، تواصل مع الدعم"));
            }
        } finally {
            setLoading(false);
        }
    }


    return (
        <>
            <div className="container d-md-flex">
                <div>
                    <ServSideBar />
                </div>
                <div>
                    <SevrHeadName title="التغليف والعلب" />
                    <div className="container rounded bg-dark-blue p-4 w-100 mx-auto text-white">
                        <h5>تفاصيل الطلب</h5>

                        {/* ///// FORM INPUTS \\\\\\ */}
                        <form encType="multipart/form-data" method="post" className="mt-3" onSubmit={handleSubmit(onSubmit)}>


                            {/* // Not Shown inpusts  \\ */}
                            <input className="d-none" type="text"
                                {...register("service")} value="Packaging and Boxes" />

                            {/* // Shown inpusts  \\ */}

                            <ServiceSelectLang
                                register={register}
                                noLang={false}
                            />

                            <ServiceNumberOfForms
                                register={register} noNumbers={false}
                            />

                            <ServiceSubServiceChoice
                                subServNAME={subServPackage}
                                bookService={false}
                                getValues={getValues}
                                setValue={setValue} />

                            <ServiceLocationDescInput
                                register={register}
                                noLocation={false}
                                errors={errors}
                            />


                            <ServiceTermsAndUpload
                                fileError={fileError}
                                setError={setFileError}
                                errors={errors}
                                setValue={setValue}
                                register={register} />


                            <ServiceSubmitButton

                                loading={loading} />

                        </form>

                        <SuccessErrorMsg
                            ToastContainer={ToastContainer}
                            errorMessage={errorMessage}
                            dataUpdate={dataUpdate}
                            successMessage={"تم إرسال طلبكم بنجاح..شكرا لك .. جاري الانتقال للصفحة الرئيسية"} />

                    </div>

                </div>
            </div>
        </>
    );
};



