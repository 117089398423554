import UserSideBar from "../components/UserSideBar";
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie';
import { handleImageChange } from "../helpers/HandleFunctions/HandelImageChange"
import { Link } from "react-router-dom";

import { apiFetch } from '../helpers/apiFetch';
import moment from 'moment';
import { userDataUpdate } from "../helpers/apiAxios";
import SuccessErrorMsg from "../components/SuccessErrorMsg";
import { toast, ToastContainer } from "react-toastify";

export default function MyAccount() {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);


    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    // const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [image, setImage] = useState("/images/user0-img.jpg");
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const userImage = profile?.data?.image
    const isoDate = profile?.data?.birthDate;
    const formattedBirthDate = moment(isoDate).format('YYYY-MM-DD');

    async function onSubmit(formData) {
        setLoading(true);
        try {
            const response = await userDataUpdate(formData);

            if (response.data.isSuccess) {
                // setDataUpdate(true);
                toast.success("تم تحديث البيانات")
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                console.log(formData);

            } else {
                setErrorMessage(toast.error("حدث خطأ ما، حاول مرة أخرى"));
            }
        } catch (error) {
            console.error("Error during form submission: ", error.response.data.message);
            switch (error.response.status) {
                case 413:
                    setErrorMessage(toast.warn("حجم الصورة كبير"));
                    break;
                case 400:
                    setErrorMessage(toast.warn("تأكد من إدخال جميع البيانات بالشكل الصحيح"));
                    break;
                default:
                    setErrorMessage(toast.warn("حدث خطأ ما، تواصل مع الدعم"));
            }

            // setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        async function fetchProfile() {
            try {
                const data = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);
                setProfile(data);

            } catch (err) {
                setError("هناك مشكلة تواصل مع الدعم");
            }
        }

        fetchProfile();
    }, []);

    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!profile) {
        return <div className="loader"></div>;
    }

    moment.locale('en');

    return (
        <div className='container dashboard col-12 position-relative d-flex'>
            <div className='container col-1 col-sm-2 m-0 text-start '>
                <UserSideBar />
            </div>
            <div className='container mt-4 col-11 col-md-10'>
                <h6 className="text-md-end m-0 c-darkOrange text-center"><span className="text-black">أهلا/ </span>
                    {profile?.data?.firstName}
                </h6>
                <div className="container mt-3 rounded h-75 bg-dark-blue">
                    <form className='d-flex flex-column flex-md-row gap-2'
                        onSubmit={handleSubmit(onSubmit)}>

                        <div className="d-flex flex-column col-11 col-md-8 mx-auto my-5 gap-3">

                            <div className="input-group input-group-sm">
                                <span className="input-group-text">الاسم الأول</span>
                                <input className={`form-control ${errors.firstName && "border border-danger"}`} type="text" placeholder="الاسم الأول"
                                    defaultValue={profile?.data?.firstName}
                                    {...register("firstName", { required: true, minLength: 3 })} />
                                {errors.firstName && <p className='errors-msg'>الاسم مطلوب أكبر من ٣ أحرف</p>}
                            </div>
                            <div className="input-group input-group-sm">
                                <span className="input-group-text">الاسم الأخير</span>
                                <input className={`form-control ${errors.lastName && "border border-danger"}`} type="text" placeholder="الاسم الأخير"
                                    defaultValue={profile?.data?.lastName}
                                    {...register("lastName", { required: true, minLength: 3 })} />
                                {errors.lastName && <p className='errors-msg'>الاسم مطلوب أكبر من ٣ أحرف</p>}
                            </div>

                            <div className="input-group input-group-sm">
                                <span className="input-group-text" >البريد</span>
                                <input className='form-control' type="text" placeholder="البريد الالكتروني"
                                    disabled
                                    value={profile?.data?.email}
                                    {...register("email")} />
                                {errors.email && <p className='errors-msg'>أدخل البريدالالكتروني</p>}
                            </div>

                            <div className="input-group input-group-sm">
                                <span className="input-group-text" >رقم الجوال</span>
                                <input className='form-control text-start' type="tel" placeholder="05xxxxxxxx"
                                    defaultValue={profile?.data?.mobilePhone}
                                    {...register("mobilePhone", { required: true })} />
                                {errors.mobilePhone && <p className='errors-msg'>أدخل رقم الجوال الصحيح</p>}
                            </div>


                            <div className="input-group input-group-sm">
                                <span className="input-group-text" >تاريخ الميلاد</span>
                                <input
                                    className='form-control text-start'
                                    type="date"
                                    placeholder="تاريخ الميلاد"
                                    defaultValue={formattedBirthDate}
                                    {...register("birthDate")} />
                            </div>
                            {errors.birthDate && <p className='errors-msg'>تاريخ الميلاد مطلوب</p>}
                            <div className="input-group input-group-sm">
                                <span className="input-group-text" >إعادة تعيين كلمة السر</span>
                                <input className='form-control'
                                    type={showPassword ? "text" : "password"}
                                    placeholder="كلمةالسر"
                                    name='password'
                                    maxLength={16}
                                    minLength={8}
                                    onChange={e => setValue("password", e.target.value)}
                                // {...register("password", { minLength: 8, maxLength: 16 })}
                                />
                                <span className="input-group-text"
                                    onClick={() => setShowPassword(!showPassword)}>
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </span>
                                {errors.password && <p className='errors-msg'>كلمة المرور تكون من ٨-١٠ أحرف</p>}
                            </div>

                        </div>

                        <div className="d-flex flex-column col-11 col-md-3 mx-auto my-1 my-md-5 gap-3 justify-content-between">

                            <div className="profile-image-container position-relative mx-auto">
                                <img crossOrigin="anonymous"
                                    className="profile-image mx-auto"
                                    src={!userImage ? image : (image !== '/images/user0-img.jpg' ? image : userImage)}
                                    // src={image !== '/images/user0-img.jpg' ? image : userImage}
                                    alt={`${profile?.data?.role}_${profile?.data?.id}_Img`}

                                />
                                <h6 className="profile-change-text border text-center">تغيير الصورة</h6>

                                <input
                                    className=" opacity-0 profile-image-input"
                                    type="file"
                                    accept="image/jpeg"
                                    onChange={(e) => handleImageChange(e, setValue, setImage, setErrorMessage)}
                                />
                            </div>
                            <button
                                className='btn-masar btn-dark-orange text-white w-75 mx-auto'
                                type="submit">
                                {loading &&
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                }
                                تعديل
                            </button>

                        </div>
                    </form>
                    <SuccessErrorMsg
                        ToastContainer={ToastContainer}
                    // errorMessage={errorMessage}
                    // dataUpdate={dataUpdate}
                    // successMessage={"تم تحديث البيانات"} 
                    />
                </div>
            </div>
        </div>
    )
};
