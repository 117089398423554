import React, { Component, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";


export default function LogIn() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (Cookies.get('token')) {
      navigate("/")
    }
  }, [navigate]);


  async function onSubmit(data) {
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/auth/login`, {
        method: "POST",
        headers: {
          "accept": "*/*",
          "Content-Type": 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (response.status === 401 || response.status === 400) {
        setErrorMessage("هناك خطأ في بيانات الدخول")
        return;
      }

      const responseData = await response.json();
      const token = responseData.data.accessToken;

      // Save token to localStorage
      Cookies.set('token', token, { expires: 7, secure: true });


      // localStorage.setItem("token", token);

      // Redirect to another page after successful login
      navigate('/');
    }
    catch (error) {
      console.error("An error occurred during login", error);
      setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className='dashboard bg-light-blue col-12 position-relative'>
      <div className='bg-white h-50'></div>
      {loading ?
        <div className="loader"></div>
        :
        <div className='dashboard-form container col-10 col-md-6 px-4 py-5 text-start h-50 bg-dark-orange rounded-4 border border-2 shadow border-white position-absolute top-50 start-50 translate-middle'>
          <h4 className='text-white'>تسجيل الدخول</h4>
          <form className='d-flex flex-column mx-auto my-5 gap-3 text-start' onSubmit={handleSubmit(onSubmit)}>
            <input
              className='form-control'
              type="text"
              placeholder="البريد الالكتروني"
              {...register("email", { required: true })} />
            {errors.email && <p className='errors-msg bg-warning p-1 rounded'>أدخل البريدالالكتروني</p>}


            <div className="input-group input-group-sm">
              <input
                className='form-control'
                type={showPassword ? "text" : "password"}
                placeholder="كلمةالسر"
                {...register("password", { required: true })} />
              <span className="input-group-text"
                onClick={() => setShowPassword(!showPassword)}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {errorMessage && <p className='errors-msg bg-warning p-1 rounded' id='error-message'>{errorMessage}</p>}

            <Link className='text-white'
              to='/resetPassword'
            >نسيت كلمة المرور؟</Link>

            <p>ليس لديك حساب على مسار؟
              <br /><Link className='text-white text-decoration-none' to="/Register">أنشئ حساب</Link></p>

            <input className='btn btn-light-blue text-white' type="submit" value="دخول" />
          </form>
        </div>
      }

      <div className='d-none d-sm-block col-6 bg-light-blue'>
      </div>
    </div>

  )
}
