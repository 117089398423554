import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import { apiFetch } from '../helpers/apiFetch';

function SampleNextArrow(props) {

    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, borderRadius: "50%", backgroundColor: "#bbbdbf", textAlign: "center", paddingTop: "1px" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, borderRadius: "50%", backgroundColor: "#bbbdbf", textAlign: "center", paddingTop: "1px" }}
            onClick={onClick}
        />
    );
}



export default function BooksSlider() {
    const { bookId } = useParams()
    const navigate = useNavigate();
    const [selectedBookId, setSelectedBookId] = useState("");
    const [libraryBooks, setLibraryBooks] = useState([]);
    const [error, setError] = useState(null);

    const handleBookClick = (bookId) => {
        async function fetchLibrary() {
            try {
                const book = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books/${bookId}`, {
                    method: "GET",
                    headers: {
                        "accept": "*/*"
                    },
                    body: JSON.stringify()
                });
                const bookByID = await book.json();

                // setSelectedBookId(bookByID?.data?.id);
                // console.log(book?.data?.id);
                // navigate(`book/${selectedBookId}`)
                navigate(`/masarLibrary/${bookByID?.data?.id}`);
                // navigate(`/masarLibrary`);

            } catch (err) {
                setError("هناك مشكلة في جلب الكتاب");
            }
        }

        fetchLibrary();

    };


    const settings = {
        className: "center",
        centerPadding: "60px",
        dots: true,
        infinite: true,
        speed: 3000,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 2,
        initialSlide: 0,
        swipeToSlide: true,
        rtl: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: false,
                    autoplay: false,
                    nextArrow: null,
                    prevArrow: null,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };


    useEffect(() => {
        async function fetchLibrary() {
            try {
                const booksData = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books?limit=5`, {
                    method: "GET",
                    headers: {
                        "accept": "*/*"
                    },
                    body: JSON.stringify()
                });
                const responseData = await booksData.json();


                setLibraryBooks(responseData);


            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchLibrary();
    }, []);


    // useEffect(() => {
    //     async function fetchLatestBooks() {
    //         try {
    //             const booksData = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books?page=1&limit=5`);

    //             setLibraryBooks(booksData);

    //         } catch (err) {
    //             setError("هناك مشكلة في جلب البيانات");
    //         }
    //     }

    //     fetchLatestBooks();
    // }, []);

    return (
        <div className="slider-container container text-center">
            <Slider {...settings}>
                {libraryBooks?.data?.result.map((item, index) => (
                    <div key={index} className="book-container text-center ">
                        <Link className="text-decoration-none text-black"
                            onClick={() => handleBookClick(item.id)}>
                            <img key={index}
                                crossOrigin="anonymous"
                                src={item.thumbnail ? item.thumbnail : "../images/BookSample-1.png"}
                                className="m-auto d-block rounded-2"
                                alt={`book-id-${item.id}`}
                            />
                            <div className="book-title p-1 rounded">
                                <p>{item.title}</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </Slider>
            {error && <p className="text-center text-warning">{error}</p>}

        </div>
    );

}