import { Link } from "react-router-dom"
import BooksSlider from "./BooksSlider"
import LatestBooksAdv from "./LatestBooksAdd"


export default function LatestBooks() {

    return (
        <div className="container py-4 mt-4">
            <div className="d-flex justify-content-between align-items-start">
                <div className="d-flex justify-content-between  flex-column">
                    <h3>أحدث الكتب</h3>
                    <p>التي لدينا في منصة الكتب… قم بزيارة منصة الكتب </p>
                </div>
                <Link className="btn btn-masar btn-light-blue" to="/masarLibrary">المزيد</Link>
            </div>
            {/* <LatestBooksAdv /> */}
            <BooksSlider />

        </div>
    )
}
