import React, { Component, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import SuccessErrorMsg from "../components/SuccessErrorMsg";
import { passwordValidation } from '../helpers/Validations/inputsValidations';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';


export default function ActiveUserPassword() {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const [success, setSuccess] = useState(false);

  async function onSubmit(data) {
    // setLoading(true);
    // console.log(data);

    try {
      await axios.patch(
        `${process.env.REACT_APP_MASAR_API_URL}/auth/reset-password/${token}`, data,
        {
          headers: {
            'Accept': '*/*',
            'Content-Type': 'application/json'
          }
        }
      )
      // setSuccess(true)
      toast.success("تم تسجيلكم بنجاح..شكرا لك .. جاري الانتقال لصفحة التسجيل")
      setTimeout(() => {
        navigate('/logIn');
      }, 3000);
    } catch (error) {
      console.error("An error occurred during login", error);
      setErrorMessage(toast.warn("حدث خطأ ما، حاول مرة أخرى"));
      setTimeout(() => {
        navigate('/resetPassword');
      }, 3000);

    } finally {
      setLoading(false);
    }
  };


  return (
    <div className='dashboard bg-light-blue col-12 position-relative'>
      <div className='bg-white h-50'></div>
      {loading ?
        <div className="loader"></div>
        :
        <div className='dashboard-form container col-10 col-md-6 px-4 py-5 text-start h-50 bg-dark-orange rounded-4 border border-2 shadow border-white position-absolute top-50 start-50 translate-middle'>
          <h4 className='text-white'>تفعيل الحساب</h4>
          <form className='d-flex flex-column mx-auto my-5 gap-3 text-start' onSubmit={handleSubmit(onSubmit)}>
            <input className='form-control' type="Password" placeholder="كلمةالسر"
              {...register("password", { required: true, pattern: passwordValidation })} />
            {errors.password && <p className='errors-msg'>كلمة المرور تكون من ١٠ أحرف بدون أي حروف خاصة</p>}

            <input className='form-control' type="password" placeholder="إعادة كلمةالسر"
              {...register("confirmedPassword", {
                required: true, validate: (val) => {
                  if (watch('password') !== val) { return "Your passwords do no match"; }
                },
              })} />
            {errors.password !== errors.confirmedPassword && <p className='errors-msg'>كلمة المرور ليست متطابقة</p>}


            <input className='btn btn-light-blue text-white' type="submit" value="تسجيل" />
          </form>
          <SuccessErrorMsg
            ToastContainer={ToastContainer}
          // errorMessage={errorMessage}
          // dataUpdate={success}
          // successMessage={"تم تسجيلكم بنجاح..شكرا لك .. جاري الانتقال لصفحة التسجيل"}
          />

        </div>
      }

      <div className='d-none d-sm-block col-6 bg-light-blue'>
      </div>
    </div>

  )
}
