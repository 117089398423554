import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { apiFetchAdmin } from '../helpers/apiFetch';
import { allBooksCategories } from "../components/Data/CategoriesData"
import { addBooksform } from "../helpers/apiAxios"
import { validateWordCount } from "../helpers/Validations/validateWords"

export function AddBooks() {
    const [errorMessage, setErrorMessage] = useState('');
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleSelect = (e) => {
        const selectedValue = e.target.value;
        const category = allBooksCategories.find(cat => cat.en === selectedValue);
        setSelectedCategory(category);
        setValue("category", selectedValue);
        // Use category.en for internal logic
    };


    const thisYear = new Date().getFullYear();


    async function onSubmit(formData) {
        setLoading(true);
        console.log(formData);

        try {
            const response = await addBooksform(formData);

            if (response.data.isSuccess) {
                setDataUpdate(true);
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
                console.log(formData);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى", response.data.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
        }
    }



    return (
        <div id="add-book"
            className="modal fade bg-transparent"
            tabIndex="-1"
            aria-hidden="true"
            aria-labelledby="moderatorAddForm"
            data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="moderatorAddForm">إضافة كتاب</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-0">
                        <form encType="multipart/form-data" method="post" className='d-flex gap-2 text-start pe-5 ps-3 pb-4'
                            onSubmit={handleSubmit(onSubmit)}>

                            <div className="d-flex flex-column col-12 ms-3 mb-5 mt-4 gap-1">
                                <div className="input-group input-group-sm">
                                    <span className="input-group-text">التصنيف</span>
                                    <select className="form-select" aria-label="add-books-category"
                                        name='category'
                                        {...register("category", { required: true })}
                                        onChange={() => handleSelect}>
                                        <option value="">اختر التصنيف</option>
                                        {allBooksCategories.map((category, index) =>
                                            <option key={index} value={category.en}>{category.ar}</option>
                                        )}
                                    </select>
                                    {errors.category && <p className='errors-msg'>مطلوب</p>}
                                </div>


                                <div className="input-group input-group-sm">
                                    <span className="input-group-text">اسم الكتاب</span>
                                    <input className={`form-control ${errors.title && "border border-danger"}`}
                                        type="text"
                                        name='title'
                                        {...register("title", { required: true, minLength: 10 })} />
                                    {errors.title && <p className='errors-msg'>الاسم مطلوب أكبر من ١٠ أحرف</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text">اسم المؤلف</span>
                                    <input className={`form-control ${errors.author && "border border-danger"}`}
                                        type="text"
                                        {...register("author", { required: true, minLength: 10 })} />
                                    {errors.author && <p className='errors-msg'>الاسم مطلوب أكبر من ١٠ أحرف</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >تاريخ النشر</span>
                                    <input className='form-control text-start' type="number" placeholder="ارقام السنة فقط"
                                        {...register("publishedDate", {
                                            required: true,
                                            pattern: /^\d{4}$/,
                                            min: 1980, max: { thisYear },
                                            valueAsNumber: true
                                        })} />
                                    {errors.publishedDate && <p className='errors-msg'> تاريخ النشر أكبر من ١٩٨٠م</p>}
                                </div>

                                <input className='form-control d-none'
                                    type="number" {...register("rating", { valueAsNumber: true })}
                                    value={5} />


                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >عدد الصفحات</span>
                                    <input
                                        className={`form-control border`}
                                        type="number"
                                        {...register("numberOfPages", { min: 20, max: 2000, valueAsNumber: true })} />
                                    {errors.numberOfPages && <p className='errors-msg'> عدد الصفحات أكبر من ٢٠ صفحة</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >وصف للكتاب</span>
                                    <textarea className="form-control form-control-sm"
                                        rows={4}
                                        {...register("description", {
                                            required: "الوصف مطلوب",
                                            validate: validateWordCount
                                        })}
                                    ></textarea>
                                    {errors.description && <p className="text-danger">{errors.description.message}</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >السعر</span>
                                    <input
                                        className={`form-control border`}
                                        type="number" step="any"
                                        {...register("price", { required: true, valueAsNumber: true })} />
                                    {errors.price && <p className='errors-msg'> السعر مطلوب</p>}
                                </div>

                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >الكمية</span>
                                    <input
                                        className={`form-control border`}
                                        type="number"
                                        {...register("quantity", { required: true, valueAsNumber: true })} />
                                    {errors.quantity && <p className='errors-msg'> السعر مطلوب</p>}
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input"
                                        type="checkbox" id="book-Available"
                                        onChange={(e) => setValue("available", e.target.checked)}
                                    // {...register("available")} 
                                    />
                                    <label className="form-check-label" htmlFor="book-Available">توفر الكتاب</label>
                                </div>


                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" >صورة</span>
                                    <input className='form-control'
                                        type="file"
                                        accept="image/JPG"
                                        onChange={e => setValue("thumbnail", e.target.files[0])}
                                    // {...register("thumbnail", { required: true })}
                                    />
                                    {errors.thumbnail && <p className='errors-msg'>يجب أن يكون صورة</p>}
                                </div>

                                {/* <div className="input-group input-group-sm d-none">
                                    <span className="input-group-text" >صورة مقاس ٢٥٠×٣٠٠ بكسل</span>
                                    <input className='form-control'
                                        type="file"
                                        accept=".image/jpg"
                                        value=""
                                        {...register("thumbnail", { required: true })} />
                                    {errors.file && <p className='errors-msg'>يجب أن يكون صورة</p>}
                                </div> */}

                            </div>
                            <button
                                className='btn-masar btn-dark-orange text-white col-2 me-3 mb-3 position-absolute bottom-0 end-0'
                                type="submit">
                                {loading &&
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                }
                                إضافة
                            </button>

                            {/* <input className='btn btn-dark-orange text-white col-2 me-3 mb-3 position-absolute bottom-0 end-0 ' type="submit" value="إضافة" /> */}

                        </form>
                        {errorMessage &&
                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                {errorMessage}
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}

                        {dataUpdate &&
                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                تم إرسال الطلب .. شكرا لك
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}

                    </div>
                </div>
            </div>
        </div>

    )
}

