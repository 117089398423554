import { allBooksCategories } from "../components/Data/CategoriesData"
import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import LibraryBook from "./libraryBook";
import RequestAddBook from "../components/RequestAddBook";
import Popup from 'reactjs-popup';
import { apiFetch } from '../helpers/apiFetch';
import Pagination from "../helpers/Pagination";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';



export default function MasarLibrary() {
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedBookId, setSelectedBookId] = useState("");
    const [libraryBooks, setLibraryBooks] = useState("");
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLogin, setIsLogin] = useState(false);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [sortState, setSortState] = useState("sortNone");



    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredBooksData = libraryBooks?.data?.result.filter(item =>
        item.title.includes(searchQuery) ||
        item.author.includes(searchQuery) ||
        item.publishedDate.toString().includes(searchQuery)
    );


    const totalPages = libraryBooks?.data?.paginationInfo.totalPagesCount;


    const sortNone = (a, b) => (a.title < b.title ? -1 : 1)
    const priceAscending = (a, b) => (a.price < b.price ? -1 : 1)
    const priceDescending = (a, b) => (a.price > b.price ? -1 : 1)

    // console.log(sortState);


    useEffect(() => {
        async function fetchLibrary() {
            try {
                const booksData = await fetch(`${process.env.REACT_APP_MASAR_API_URL}/library/books?${searchQuery ? "" : `${selectedCategory ? `type=${selectedCategory}&` : ""}page=${currentPage}&limit=18`}`, {
                    method: "GET",
                    headers: {
                        "accept": "*/*"
                    },
                    body: JSON.stringify()
                });
                const responseData = await booksData.json();


                setLibraryBooks(responseData);


            } catch (err) {
                setError("هناك مشكلة في جلب البيانات");
            }
        }

        fetchLibrary();
    }, [currentPage, selectedCategory, searchQuery]);


    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!libraryBooks) {
        return <div className="loader"></div>;
    }


    return (
        <div className="container mt-4">

            <div className="d-flex flex-wrap flex-row flex-md-row align-items-center">

                <h3 className="c-darkOrange col-12 col-md-3">مكتبة مسار</h3>

                <input
                    name="queryBooks"
                    className="form-control-sm border my-2 col-8 col-md-4"
                    type="search"
                    placeholder={
                        selectedCategory
                            ? `البحث في تصنيف ${allBooksCategories.find(category => category.en === selectedCategory)?.ar || ""
                            }`
                            : `البحث باسم المؤلف / الكتاب / سنة النشر`
                    }
                    value={searchQuery}
                    onChange={handleSearchChange} />
                <div className="col-4 col-md-2 px-2">
                    <select id="sortBooks" className="form-select form-select-sm"
                        onChange={e => setSortState(e.target.value)}>
                        <option value="sortNone">ترتيب بالأسم</option>
                        <option value="priceAscending">السعر تصاعدي</option>
                        <option value="priceDescending">السعر تنازلي</option>
                    </select>
                </div>
                {Cookies.get('token') ?
                    <Popup trigger={<button className="btn btn-masar btn-light-blue col-8 col-md-1 mb-2 mx-auto">إضافة كتاب</button>} position="bottom left" modal>
                        <RequestAddBook />
                    </Popup> : ""}
            </div>

            <div className="d-flex flex-wrap justify-content-center">
                <select className="form-select d-md-none" value={selectedCategory}
                    onChange={handleChange}>
                    <option value="">كل الكتب</option>
                    {allBooksCategories.map((category, index) => (
                        <option key={index} value={category.en} >
                            {category.ar}
                        </option>
                    ))}
                </select>

                <ul className={`d-none d-md-block list-group col-6 ${selectedBookId ? "col-md-12 mb-3 text-center" : "col-md-3"} pt-2 h-100`}>
                    {selectedBookId ?
                        <li className={`list-group-item list-group-item-action ps-2 bg-light-gray`}
                            onClick={() => (setSelectedCategory(null),
                                setSelectedBookId(null))}>
                            كل الكتب
                        </li>
                        :
                        <>
                            <li className={`list-group-item list-group-item-action ps-2 bg-light-gray`}
                                onClick={() => (setSelectedCategory(null),
                                    setSelectedBookId(null))}>
                                كل الكتب
                            </li>
                            {allBooksCategories.map((category, index) => (
                                <li key={index} className={`list-group-item list-group-item-action ps-2 ${selectedCategory === category.en ? 'active' : ''}`} onClick={() => setSelectedCategory(category.en)}>
                                    {category.ar}
                                </li>
                            ))}
                        </>}
                </ul>
                {/* {selectedBookId ?
                    <LibraryBook bookSetId={selectedBookId} setSelectedCategory={setSelectedCategory} /> : */}
                <div className="d-flex flex-wrap flex-row align-content-start justify-content-center col-11 col-md-9 pt-1 ">

                    {(searchQuery ?
                        (filteredBooksData)
                        : (libraryBooks?.data?.result)).sort(
                            sortState === "sortNone" ? sortNone :
                                (sortState === "priceAscending" ? priceAscending :
                                    (sortState === "priceDescending" ? priceDescending : ""))
                        ).map((item) => (
                            item.quantity > 0 ?
                                <div
                                    key={item.id}
                                    // className="book-container col-12 col-sm-4 col-lg-3 col-xxl-2 my-2"
                                    className="book-container col-6 col-sm-4 col-xl-3 col-xxl-2 my-2 position-relative"
                                    onClick={() => navigate(`/masarLibrary/${item.id}`)}
                                // onClick={() => handleBookClick(item.id)}
                                >
                                    {sortState !== "sortNone" && <span className="position-absolute top-25 end-0 badge rounded-pill bg-info">
                                        {item.price} ريال
                                        <span className="visually-hidden">unread messages</span>
                                    </span>}
                                    <Link className="text-decoration-none text-black">
                                        <img
                                            crossOrigin="anonymous"
                                            // key={item.id}
                                            // to remove (!) when image of book allowed //
                                            src={item.thumbnail ? item.thumbnail : "../images/BookSample-1.png"}
                                            alt={`book-id-${item.id}`}
                                            className="rounded-2" />
                                        <div className="book-title p-1 rounded">
                                            <p>{item.title}</p>
                                        </div>
                                    </Link>
                                </div> : ""
                        ))}

                </div>
                <div className="col-4">

                    {totalPages > 1 &&
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages} />
                    }
                </div>

                {/* } */}
            </div>

        </div>
    );
}
