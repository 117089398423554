import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { allProfilesCategories } from "../components/Data/CategoriesData"
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ReCaptchaForForms, { ReCaptchaHidden } from '../helpers/ReCaptchaForForms';
import ReactWhatsapp from 'react-whatsapp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Map from '../components/contactUs/googleMap';
import { apiFetch } from "../helpers/apiFetch"
import { emailValidation, mobileValidation } from "../helpers/Validations/inputsValidations"

export default function ContactUs() {
    const form = useRef();
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [dataUpdate, setDataUpdate] = useState(false);



    async function onSubmit(data) {
        setLoading(true);
        console.log("Form data: ", data); // Debug: Check form data

        try {
            const response = await apiFetch(`${process.env.REACT_APP_MASAR_API_URL}/mailer/contact-us`, {
                method: "POST",
                body: JSON.stringify(data)
            });

            // Redirect to another page after successful submission
            if (response.isSuccess) {
                setDataUpdate(true)
                setTimeout(() => {
                    navigate('/');
                }, 5000);

            } else {
                // console.log(response, response.message, response.status);
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى", response.message);
            }
        } catch (error) {
            console.error("Error during form submission: ", error); // Debug: Check error
            setErrorMessage("حدث خطأ ما، تواصل مع الدعم");
        } finally {
            setLoading(false);
            // navigate('/');

        }
    }


    // For Mail JS keep as backup
    // function sendEmail() {
    //     setLoading(true)

    //     emailjs
    //         .sendForm(`${process.env.REACT_APP_EMAILJS_SERVICE_ID}`, `${process.env.REACT_APP_EMAILJS_CONTACTUS_ID}`, form.current, {
    //             publicKey: `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY}`,
    //         })
    //         .then(
    //             () => {
    //                 console.log('SUCCESS!');
    //                 setLoading(false)
    //                 setSuccess(true)
    //                 setTimeout(() => {
    //                     // window.location.reload();
    //                     navigate('/');
    //                 }, 5000);
    //             },
    //             (error) => {
    //                 console.log('FAILED...', error.text);
    //                 setErrorMessage('يوجد مشكلة تواصل معنا', error.text);
    //                 setLoading(false)
    //             },
    //         );
    // };


    return (

        <div>
            <div className='text-center bg-light-gray my-4 py-3'>
                <img
                    src='./images/contactUsHeader.png'
                    alt='contact us header information'
                    className='contact-us-header'
                />
            </div>
            <div className='text-start'>
                <div className='container d-flex flex-row justify-content-between'>
                    <h4 className=' c-darkOrange'>تواصل معنا</h4>

                    <Link className="btn btn-outline-success align-self-end w-auto"
                        to={'https://wa.me/966508684795'} target='blank'
                    >
                        تواصل معنا على الواتساب <FontAwesomeIcon icon={faWhatsapp} className='fs-4' />
                    </Link>
                </div>
                <form id='contactUs-form' className='bg-light-blue-10 rounded shadow d-flex flex-column mx-auto my-4 gap-2 p-4 w-75 w-lg-50'
                    ref={form} onSubmit={handleSubmit(onSubmit)}
                    method="POST">

                    <div className="input-group input-group-sm">
                        <span className="input-group-text">الاسم</span>
                        <input className={`form-control ${errors.name && "border border-danger"}`}
                            type="text"
                            {...register("name", { required: true, minLength: 10 })} />
                        {errors.name && <p className='errors-msg'>الاسم مطلوب أكبر من ١٠ أحرف</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text">البريد</span>
                        <input className={`form-control ${errors.email && "border border-danger"}`}
                            type="email"
                            {...register("email",
                                { required: true, pattern: emailValidation })} />
                        {errors.email && <p className='errors-msg'>أدخل البريدالالكتروني</p>}
                    </div>

                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >رقم الجوال</span>
                        <input className='form-control text-start' type="tel" placeholder="05xxxxxxxx"
                            {...register("phone", { required: true, minLength: 10, pattern: mobileValidation })} />
                        {errors.phone && <p className='errors-msg'>أدخل رقم الجوال الصحيح</p>}
                    </div>


                    <div className="input-group input-group-sm">
                        <span className="input-group-text" >تفاصيل</span>
                        <textarea
                            className={`form-control form-control-sm`}
                            type="text"
                            rows={4}
                            {...register("description")}
                        />
                        {errors.description && <p className='errors-msg'>أختر مجالا على الأقل</p>}
                    </div>


                    <div className="align-self-end">
                        <ReCaptchaHidden />
                    </div>


                    <button className="btn btn-light-blue text-white align-self-end w-auto"
                        type='submit'>
                        {loading &&
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                        }
                        أرسل
                    </button>

                </form>
                {errorMessage &&
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        {errorMessage}
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>}

                {dataUpdate &&
                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                        تم ارسال رسالتكم بنجاح .. شكرا لك
                        سنتواصل معكم في اقرب وقت
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>}
                <Map />
            </div>
        </div>

    );
};

