import { AdminSideBar } from "../components/UserSideBar";
import React, { useState, useEffect } from 'react';
import { apiFetchAdmin } from '../helpers/apiFetch';
import moment from 'moment';
import 'moment/locale/ar'
import Popup from 'reactjs-popup';
import { ModiratorData } from "../components/ModiratorData";
import Pagination from "../helpers/Pagination";
import { AddModirators } from "../components/AddModirators";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from "@fortawesome/free-solid-svg-icons";


export default function AdminModirators() {
    const [modirators, setModirators] = useState(null);
    const [error, setError] = useState(null);
    // const [selectedModirator, setSelectedModirator] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();

    const totalPages = modirators?.data?.paginationInfo.totalPagesCount;


    async function handleUserDelete(id) {

        try {
            await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/${id}`, { method: "DELETE" });
            const updatedUserData = {
                ...modirators,
                data: {
                    ...modirators.data,
                    result: allModiratorsData.filter((modirator) => modirator.id !== id)
                }
            };
            setModirators(updatedUserData);

        } catch (err) {
            setError("هناك مشكلة في الحذف");
        }
    }


    const Avatar = ({ firstName, lastName, email, mobilePhone, role, image, id }) => (
        <div className="modireator-avatar d-flex flex-column align-items-center m-2 text-center bg-white rounded-3 position-relative">
            <button className="btn-masar btn-dark-orange text-white p-1 rounded position-absolute top-0 end-0 m-2"
                onClick={() => handleUserDelete(id)}
            ><FontAwesomeIcon icon={faEraser}
                /></button>

            <Popup trigger={<img crossOrigin="anonymous" src={image ? image : '../images/user0-img.jpg'} className="avatar-img p-2" alt={`${role}_${id}_Img`} />} position="bottom left" modal>
                <ModiratorData id={id} userImage={image} firstName={firstName} lastName={lastName} role={role} mobilePhone={mobilePhone} email={email} />
            </Popup>
            <div className="mt-1">{firstName}</div>
            <div className="c-lightBlue">{role}</div>
        </div>
    );



    moment.locale('ar_SA');
    const date = moment().format('Do MMMM YYYY, h:mm:ss a');

    const isSuperAdmin = profile?.data?.role === "superAdmin";
    const allModiratorsData = modirators?.data?.result;
    // console.log(allModiratorsData);




    useEffect(() => {
        if (!Cookies.get('adminToken')) {
            // if (!localStorage.getItem("adminToken")) {
            navigate("/adminLogin")
        }
    }, [navigate])


    async function fetchModirators() {
        try {
            const data = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/moderators?page=${currentPage}&limit=11`);
            const profileData = await apiFetchAdmin(`${process.env.REACT_APP_MASAR_API_URL}/users/my-profile`);


            setProfile(profileData);
            setModirators(data);


        } catch (err) {
            setError("هناك مشكلة في جلب البيانات");
        }
    }


    useEffect(() => {
        fetchModirators();
    }, [currentPage]);


    if (error) {
        return <div className="text-center my-4 text-danger">{error}</div>;
    }

    if (!modirators) {
        return <div className="loader"></div>;
    }



    return (
        <div className='container dashboard col-12 position-relative d-flex p-0'>
            <div className='col-1 col-md-2 text-start bg-light-blue'>
                <AdminSideBar superAdminRole={profile?.data?.role} />
            </div>
            <div className='container mt-4 col-10'>
                <h6 className="text-end m-0 c-darkOrange"><span className="text-black">أهلا/</span> {profile?.data?.firstName}</h6>
                <div className="container my-3 rounded bg-light-gray position-relative p-3">
                    <div className="d-flex flex-wrap flex-column flex-md-row justify-content-center p-2">
                        {allModiratorsData.map((modirator) => (
                            <Avatar key={modirator.id} id={modirator.id} image={modirator.image}
                                firstName={modirator.firstName} lastName={modirator.lastName} role={modirator.role} mobilePhone={modirator.mobilePhone} email={modirator.email} />
                        ))}
                        <div className="add-modirator d-flex justify-content-center align-items-center rounded-3 bg-white my-2 mx-auto m-md-2 fs-1 c-darkGray"
                            data-bs-toggle="modal"
                            data-bs-target="#add-moderator">
                            +
                        </div>
                    </div>
                </div>
                {totalPages > 1 &&
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages} />
                }

            </div>
            <AddModirators />

        </div>
    )
}