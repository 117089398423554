import axios from 'axios';
import Cookies from 'js-cookie';


const token = Cookies.get('token');
const adminToken = Cookies.get('adminToken');
const paymobSTESTK = process.env.REACT_APP_PAYMOB_SECRET_TESTKEY;
const paymobSK = process.env.REACT_APP_PAYMOB_SECRET_KEY;


const apiAxiosAdmin = axios.create({
    baseURL: process.env.REACT_APP_MASAR_API_URL,
    headers: {
        'Authorization': `Bearer ${adminToken}`,
        "Content-Type": 'multipart/form-data'
    }
});





// Modify Admin dashbord Data
export const editAdminData = (data) => apiAxiosAdmin.patch('/users/moderators', data)

/// Modirators ///
// add Modirators Form
export const addModiratorsForm = (data) => apiAxiosAdmin.post('/users/moderators', data)
// Modify Modirators Data
export const editModiratorsData = (id, data) => apiAxiosAdmin.patch(`/users/moderators/${id}`, data)

/// Books ///
// Add book admin dashbord Data
export const addBooksform = (data) => apiAxiosAdmin.post('/library/books', data)
// Add book admin dashbord Data
export const editBooksform = (id, data) => apiAxiosAdmin.patch(`/library/books/${id}`, data)
// Add book admin dashbord Data
export const deleteBooksform = (id) => apiAxiosAdmin.delete(`/library/books/${id}`)

/// Orders ///
// Modify Orders in Admin dashbord Data
export const editOrderOffer = (orderId, data) => apiAxiosAdmin.patch(`/orders/admins/${orderId}`, data)

/// Profile Works ///
// Add work image in admin dashbord Data
export const addWorkform = (data) => apiAxiosAdmin.post('/works', data)



const api = axios.create({
    baseURL: process.env.REACT_APP_MASAR_API_URL,
    headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
    }
});

const apiAxiosUser = axios.create({
    baseURL: process.env.REACT_APP_MASAR_API_URL,
    headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": 'multipart/form-data'
    }
});

const apiAxiosUserPayment = axios.create({
    baseURL: process.env.REACT_APP_PAYMOB_BASE_URL,
    headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": 'application/json'
    }
    // body: {
    //     "auth_token": "Your Auth Token",
    //     "merchant_order_id": "your merchant order ID",
    //     "order_id": "Your Order ID"
    // }
});

export const userLogIn = (data) => api.post(`${process.env.REACT_APP_MASAR_API_URL}/auth/login`, data)
// Users
//// User add service Book
export const userServiceReqest = (data) => apiAxiosUser.post('/orders', data)
//// User patch user info
export const userDataUpdate = (data) => apiAxiosUser.patch('/users/profile', data)
//// User patch user info
export const userOfferUpdate = (orderId, data) => apiAxiosUser.patch(`/orders/${orderId}`, data)
//// User patch user info
export const transactionInquiry = (data) => apiAxiosUserPayment.post(`/api/ecommerce/orders/transaction_inquiry`, data)


// FOR TEST
const apiAxiosPaymobTest = axios.create({
    baseURL: process.env.REACT_APP_PAYMOB_BASE_URL,
    headers: {
        'Authorization': `Token ${paymobSTESTK}`,
        "Content-Type": 'application/json'
    }
});

//// FOR LIVE
const apiAxiosPaymob = axios.create({
    baseURL: process.env.REACT_APP_PAYMOB_BASE_URL,
    headers: {
        'Authorization': `Token ${paymobSK}`,
        "Content-Type": 'application/json'
    }
});


//// Create intention
export const masarPaymobInt = (data) => apiAxiosPaymob.post('/v1/intention/', data)
export const masarPaymobIntTest = (data) => apiAxiosPaymobTest.post('/v1/intention/', data)




export default apiAxiosUser;
