import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from "@fortawesome/free-solid-svg-icons";
// import BookImage from "../../../../../images/BookSample-1.png"

const CartBooksItems = ({ handleOnClick, bookTitle, bookImg, bookImgAlt, bookPrice, bookQty }) => (
    <div className="book-container mt-2 px-2 position-relative">
        {/* <p className="btn-masar bg-white text-secondary p-1 rounded position-absolute top-0 start-50 translate-middle-x m-2">{bookPrice}</p> */}
        <button className="btn-masar btn-dark-orange text-white p-1 rounded position-absolute top-0 end-0 m-2"
            onClick={handleOnClick}
        ><FontAwesomeIcon icon={faEraser}
            /></button>
        <img
            crossOrigin="anonymous"
            src={bookImg ? bookImg : "/images/BookSample-1.png"}
            alt={bookImgAlt}
            className="rounded-2 w-100" />
        <div className="pt-1">
            <p className="text-white">{bookTitle} <br />
                <span>{bookPrice} ر.س</span>{bookQty > 1 && <span> &#10005; {bookQty} كتاب</span>}
            </p>
        </div>
    </div>
);


const CartOrdersItmes = ({ handleOnClick, orderService, orderPrice }) => (
    <div className="d-flex bg-dark-gray mt-2 ms-2 col-11 col-md-9 rounded align-items-center px-2">
        <span className="col-8 tw-bold">{orderService}</span>
        <span className="col text-center">{orderPrice} ر.س</span>
        <button className="col-1 btn-masar btn-dark-orange text-white p-1 rounded m-1"
            value="Rejected"
            onClick={handleOnClick}
        ><FontAwesomeIcon icon={faEraser}
            /></button>
    </div>
);

export { CartBooksItems, CartOrdersItmes }

// <button className="btn-masar-sm btn-med-gray"
//     value="Rejected"
//     type="submit"
//     onClick={(e) => {
//         setValue("status", e.target.value);
//     }}
// >Reject</button>