import ServSideBar from "../components/ServSideBar";
import React, { useState } from 'react';
import { SevrHeadName } from "../components/SevrHeadName";
import { useForm } from 'react-hook-form';
import { userServiceReqest } from "../helpers/apiAxios"
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';

import { subServivcesBooks } from "../components/SubServices"
import ServiceTermsAndUpload from "../components/ServiceTermsAndUpload";
import ServiceSubmitButton from "../components/orders/ServiceSubmitButton";
import SuccessErrorMsg from "../components/SuccessErrorMsg";
import ServiceSubServiceChoice from "../components/ServiceSubServiceChoice";
import ServiceLocationDescInput, { ServiceNumberOfForms, ServiceSelectLang } from "../components/orders/ServiceGeneralInputs";
// import onSubmit from "../helpers/HandleFunctions/HandleServicesOnSubmit"
import { useNavigate } from 'react-router-dom';

export default function ServBooks() {
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        defaultValues: {
            subServices: '',
        }
    });
    const [loading, setLoading] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [successNotice, setSuccessNotice] =
        useState("تم إرسال طلبكم بنجاح..شكرا لك .. جاري الانتقال للصفحة الرئيسية");
    const [fileError, setFileError] = useState('');

    async function onSubmit(formData) {
        if (!Cookies.get('token')) {
            return (
                toast.warn(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link> لطلب الخدمة</p>)
            )
        }
        setLoading(true);
        try {
            const response = await userServiceReqest(formData);

            if (response.data.isSuccess) {
                toast.success(successNotice)
                setTimeout(() => {
                    navigate("/");
                }, 3000);
                console.log(formData);

            } else {
                setErrorMessage("حدث خطأ ما، حاول مرة أخرى");
            }
        } catch (error) {
            console.error("Error during form submission: ", error);
            setErrorMessage(<p className="p-0 m-0">تأكد من  <Link to="/logIn">تسجيل الدخول</Link> أو تواصل معنا لحل المشكلة</p>);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="container d-md-flex">
            <ServSideBar />
            <div>
                <SevrHeadName title="الكتب" />
                <div className="container rounded bg-dark-blue p-4 w-100 mx-auto text-white">
                    <h5>تفاصيل الطلب</h5>

                    {/* ///// FORM INPUTS \\\\\\ */}
                    <form encType="multipart/form-data" method="post" className="mt-3" onSubmit={handleSubmit(onSubmit)}>

                        <input className="d-none" type="text"
                            {...register("service", { required: true })} value="Book" />

                        <ServiceNumberOfForms
                            register={register} noNumbers={true}
                        />

                        <ServiceSelectLang
                            register={register}
                            noLang={false}
                        />

                        <ServiceSubServiceChoice
                            subServNAME={subServivcesBooks}
                            bookService={true}
                            getValues={getValues}
                            setValue={setValue} />

                        <ServiceLocationDescInput
                            register={register}
                            noLocation={false}
                            errors={errors}
                        />

                        <ServiceTermsAndUpload
                            errors={errors}
                            fileError={fileError}
                            setError={setFileError}
                            setValue={setValue}
                            register={register} />

                        <ServiceSubmitButton

                            loading={loading} />

                        {/* <button className="float-end btn btn-masar btn-dark-orange w-25" type="submit">إرسال</button> */}
                    </form>

                    <SuccessErrorMsg
                        ToastContainer={ToastContainer}
                        errorMessage={errorMessage}
                        dataUpdate={dataUpdate}
                        successMessage={"تم إرسال طلبكم بنجاح..شكرا لك .. جاري الانتقال للصفحة الرئيسية"}
                    />

                </div>
            </div>
        </div>
    );
};


